import React, { Component } from "react";
import "../../../../../assets/css/profile.less";
import { Link } from "react-router-dom";
import Select, { components } from "react-select";
import { connect } from "react-redux";
import { getGender, getStatus, getCountry, setChildProfile, getUserProfile, getParentProfile, GetStudentPlan, clearErrorList, setIsChildProfileSuccess, setIsUserProfileSuccess, editIsChildProfileSuccess, getChildProfilestatus, getParentGoals, setIsParentGoalSuccess, getGoalsFromStudent, getGoalsFromStudentStatus, editChildProfile, getChildProfile, getSchools, clearMessage, setIsGoalSuccess } from "../../../../../redux/actions";
import { withRouter } from "../../../../../redux/store/navigate";
import { Button, Form, Modal } from "react-bootstrap";
import Loader from "../../../../../components/Loader";
import CustomAlert from "../../../../../components/Alert";
import { COUNTRY_NAME_LABEL, FIRST_NAME_LABEL, GENDER_LABEL, GPA_LABEL, GRADUATION_YEAR_LABEL, High_SCHOOL_LABEL, LAST_NAME_LABEL, MY_STATUS_LABEL, ZIP_CODE_LABEL, EMAIL_LABEL } from "../../../../../Constants/commonLabels";
import { schoolApi, universityApi, loadZipcode } from "../../../../../utils/AutoComplete";
import { Grid, Autocomplete, FormControlLabel, Checkbox, Typography, Tooltip, IconButton } from '@mui/material';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import Footer from "../../../../../componentsNew/Common/Footer";


const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
        </ValueContainer>
    );
};

const timezonesOptions = [
    { label: 'Pacific Time', value: 'America/Los_Angeles' },
    { label: 'Eastern Time', value: 'America/New_York' },
    { label: 'Central Time', value: 'America/Chicago' },
    { label: 'Mountain Standard Time', value: 'America/Phoenix' },
    { label: 'Mountain Daylight Time', value: 'America/Denver' },
    { label: 'Hawaii Time', value: 'Pacific/Honolulu' },
    { label: 'Alaska Time', value: 'America/Anchorage' },
];

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: false,
            loading: false,
            fields: { firstName: "", lastName: "" },
            childId: '',
            errors: {},
            status: false,
            selectedGenderOption: null,
            selectedStatusOption: null,
            selectedCountryOption: null,
            studentsCollabCheckBox: false,
            counselingAllowedCheckBox: false,
            helpWithMilestonesCheckBox: false,
            subCouncelingServicesCheckBox: false,
            selectGraduationOption: null,
            selectSchoolOption: null,
            selectedUniversityOption: null,
            alertValue: false,
            highSchoolData: [],
            universityData: [],
            noOptionLength: "",
            isload: false,
            errorList: [],
            zipCodeOptions: [],
            selectedZipOption: null,
            timeZone: null,

        };
    }
    componentDidMount() {
        this.props.setIsUserProfileSuccess(false);
        this.props.getParentProfile()
        this.props.clearErrorList()
        this.setState({ errorList: [], loading: true, dataLoaded: false, errors: {} })
        this.props.clearMessage()
        this.props.getChildProfile()

        let getStudentId = {
            "studentId": this.props.childprofile && this.props.childprofile[0]?.id
        }
        if (this.props.childprofile !== undefined && this.props.childprofile.length > 0) {
            this.props.getGoalsFromStudent(getStudentId)
            let payloadPlan = {
                "searchString": "",
                "pageNo": 1,
                "limit": 10,
                "status": [],
                "sortOrder": "latest",
                "studentId": this.props.childprofile && this.props.childprofile[0]?.id
            }
            this.props?.GetStudentPlan(payloadPlan)
        } else if (this.props.childprofile?.length === 0 || this.props.childprofile?.length === undefined) {
            this.props.getParentGoals()
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.childprofile !== this.props.childprofile) {
            if (this.props.childprofile !== undefined && this.props.childprofile.length > 0) {
             
                let payloadPlan = {
                    "searchString": "",
                    "pageNo": 1,
                    "limit": 10,
                    "status": [],
                    "sortOrder": "latest",
                    "studentId": this.props.childprofile && this.props.childprofile[0]?.id
                };
                this.props?.GetStudentPlan(payloadPlan);
            } 
        }

        if (!this.state.status) {
            if (this.props.status !== undefined && this.props.status.length > 0) {
                this.setState({ loading: false, status: true })
            }
        }
        this.setInitialValues();
        if (this.props.isChildProfileSucess) {
            console.log(this.props.getstudentplan?.length, "this.props.getstudentplan?.length")
            if (this.props.getstudentplan?.length > 1) {
                this.props.navigate('/onboarding/selectadvisor/child')
            } else {
                this.props.navigate('/onboarding/intialplansParent')
            }
            this.props.setIsChildProfileSuccess(false)
        }
        if (this.props.editChildProfileSucess) {
            if (this.props.getstudentplan?.length > 1) {
                this.props.navigate('/onboarding/selectadvisor/child')
            } else {
                this.props.navigate('/onboarding/intialplansParent')
            }
            this.props.editIsChildProfileSuccess(false)

        }
        // Make sure this.props.childprofile is defined before using it
        if (this.props.childprofile) {
           
            if (this.props.getchildstatus) {
                if (this.props.childprofile.length > 0 && this.props.getchildstatus) {
                    let getStudentId = { "studentId": this.props.childprofile[0]?.id };
                    this.props.getGoalsFromStudent(getStudentId);
               
                    this.props.getChildProfilestatus(false);
                    // this.setState({ loading: true });
                } else if (this.props.childprofile.length === 0 && this.props.getchildstatus) {
                    this.props.getParentGoals();
                    this.props.getChildProfilestatus(false);
                    // this.setState({ loading: true });
                }
            }
        }

        if (this.props.parentGoal && this.props.isParentGoalStatus) {
            this.setState({ loading: false, dataLoaded: false })
            this.props.getGoalsFromStudentStatus(false)
            this.setInitialValues()
        }
        if (this.props.parentGoal && this.props.parentsstatus) {
            this.props.setIsParentGoalStatus(false)
            this.setState({ loading: false, dataLoaded: false })
            this.setInitialValues()
        }
    }
    componentWillReceiveProps(newProps) {
        this.setState({ highschoollist: newProps.schools, errorList: newProps.errorList });
    }

    zipLoadOptions = (value, callback) => {
        // universityApi(inputText).then((res) => callback(res.data.data?.map((item) => ({ label: item.university, value: item.university }))));
        if (value === "") {
            this.setState({ universityData: [] });
        }
        this.setState({ isload: true });
        this.setState({ noOptionLength: value });
        let list = []
        if (value !== "") {
            this.setState({ selectedZipOption: value })
            let payload = {
                searchString: value,
                pageNo: 1,
                limit: 80,
            };
            loadZipcode(payload).then((res) => {
                if (res.status === 200) {
                    res?.data?.data.map((item, index) => {
                        list.push({
                            label: item.zip,
                            value: item.zip
                        });

                    });
                    this.setState({ zipCodeOptions: list });
                } else {
                    this.setState({ zipCodeOptions: [] });
                }

                this.setState({ isload: false });
            })
        }

    }


    onTagsZipChange = (event, values) => {
        console.log("values", values)
        let { errors } = this.state;
        this.setState({ selectedZipOption: values });
        errors['zipCode'] = ""
    }

    setInitialValues() {
        if (!this.state.dataLoaded) {                  // createNew -> Parent Connect Child
            let { fields, selectedGenderOption, selectedStatusOption, selectedCountryOption, selectSchoolOption, selectGraduationOption,
                studentsCollabCheckBox, counselingAllowedCheckBox, helpWithMilestonesCheckBox, subCouncelingServicesCheckBox, selectedUniversityOption } = this.state;
            const { childprofile, country, status, graduationyear } = this.props;
            const childprofileitem = childprofile && childprofile[0]?.studentProfile;
            const childprofiledata = childprofile && childprofile[0]
            console.log(selectedStatusOption, "childprofileitem")
            const Dates = Array.from({ length: 10 }, (_, i) => new Date().getFullYear() + i);
            console.log(childprofileitem, "childprofileitem?.helpWithMilestonesCheckBox")
            let userProfile = null;
            if (this.props?.childprofile && this.props?.childprofile.length > 0 && status != undefined && country != undefined && graduationyear != undefined) {
                userProfile = childprofile[0] && childprofile[0];
                this.setState({ childId: userProfile.id ? userProfile.id : "" });
                fields["firstName"] = userProfile.firstName ? userProfile.firstName : "";
                fields["lastName"] = userProfile.lastName ? userProfile.lastName : "";
                this.setState({ selectedZipOption: userProfile.zipCode })
                fields["zipCode"] = userProfile.zipCode ? userProfile.zipCode : "";
                fields["currentGPA"] = childprofileitem.weighted ? childprofileitem.weighted : "";
                fields["unWeighted"] = childprofileitem.unweighted ? childprofileitem.unweighted : "";
                fields["email"] = userProfile.email ? userProfile.email : "";
                if (this.props.childprofile && this.props.childprofile.length > 0) {
                    selectedCountryOption = country.map((countryItem) => { return { label: countryItem?.countryName, value: countryItem?.isoCode2Digit } }).find((x) => x.value === childprofiledata?.country)
                    selectedGenderOption = this.props.childprofile.map((genderItem) => { return { label: genderItem?.gender, value: genderItem?.gender } }).find((x) => x.value === userProfile.gender)
                    selectGraduationOption = graduationyear.map((gradItem) => { return { label: gradItem, value: gradItem } }
                    ).find((x) => x.value === childprofileitem.graduationYear)
                    selectedStatusOption = status.map((statusItem) => { return { label: statusItem.status, value: statusItem.status, role: statusItem.role } }).find((x) => x.value === childprofileitem?.myStatus)
                    selectSchoolOption = { label: childprofileitem?.currrentSchoolName, value: childprofileitem?.currrentSchoolName }
                    selectedUniversityOption = { label: childprofileitem.currrentSchoolName, value: childprofileitem.currrentSchoolName }
                    const selectedTimeZone = timezonesOptions?.find(option => option?.value === childprofiledata?.timezone);
                    if (selectedTimeZone) {
                        this.setState({ timeZone: selectedTimeZone });
                    }
                }
                if (childprofileitem.studentsCollab === true) {
                    studentsCollabCheckBox = true;
                    this.refs.studentsCollabCheckBox.checked = true;
                }
                if (childprofileitem.helpWithCoursesAndActivities === true) {
                    counselingAllowedCheckBox = true;
                    this.refs.counselingAllowedCheckBox.checked = true;
                }
                if (childprofileitem?.helpWithMilestones === true) {
                    helpWithMilestonesCheckBox = true
                    this.refs.helpWithMilestonesCheckBox.checked = true;
                }
                if (childprofileitem.subCouncellingServices === true) {
                    subCouncelingServicesCheckBox = true;
                    this.refs.subCouncelingServicesCheckBox.checked = true;
                }
                this.setState({ selectedGenderOption, selectedStatusOption, selectedCountryOption, selectSchoolOption, selectedUniversityOption, selectGraduationOption, studentsCollabCheckBox, counselingAllowedCheckBox, helpWithMilestonesCheckBox, subCouncelingServicesCheckBox });
                this.setState({ dataLoaded: true, loading: false })
            }
        }

    }
    handleChange(field, e) {
        let { errors } = this.state;
        let fields = this.state.fields;
        fields[field] = e.target.value;
        if (e.target.value.length >= 0) {
            errors[field] = "";
        }
        this.setState({ fields, errors: errors, errorList: [] });
    }
    handleCheckboxChange(e, buttonName) {
        let { studentsCollabCheckBox, counselingAllowedCheckBox, helpWithMilestonesCheckBox, subCouncelingServicesCheckBox } = this.state;
        if (buttonName === "studentsCollab") {
            studentsCollabCheckBox = e.target.checked
        } else if (buttonName === "counselingAllowed") {
            counselingAllowedCheckBox = e.target.checked
        } else if (buttonName === "subCouncelingServices") {
            subCouncelingServicesCheckBox = e.target.checked
        } else if (buttonName === "helpWithMilestones") {
            helpWithMilestonesCheckBox = e.target.checked
        }
        let { errors } = this.state;
        errors["checkboxes"] = "";
        this.setState({ studentsCollabCheckBox, counselingAllowedCheckBox, subCouncelingServicesCheckBox, helpWithMilestonesCheckBox, })
    }
    handleSelectChange(options, name) {
        let { selectedGenderOption, selectedStatusOption, selectedCountryOption, selectGraduationOption, selectedUniversityOption, selectSchoolOption, errors } = this.state;
        switch (name) {
            case "gender":
                selectedGenderOption = options;
                errors["gender"] = "";
                break;
            case "status":
                selectedStatusOption = options;
                selectedUniversityOption = null
                selectSchoolOption = undefined;
                errors["status"] = "";
                errors["highSchoolName"] = "";
                this?.UniversityloadOptions("")
                this?.loadOptions("")
                break;
            case "country":
                selectedCountryOption = options;
                errors["country"] = "";
                break;
            case "graduationYear":
                selectGraduationOption = options;
                errors["graduationYear"] = "";
                break;
            case "highSchoolName":
                selectSchoolOption = options;
                selectedUniversityOption = options
                errors["highSchoolName"] = "";
                break;
        }
        this.setState({ selectedGenderOption, selectedStatusOption, selectedUniversityOption, selectedCountryOption, selectGraduationOption, selectSchoolOption, errors: errors });
    }
    loadSchoolOptions = async (inputText, callback) => {
        let { highschoollist } = this.state;
        this.props.getSchools({ "searchString": inputText })
        callback(highschoollist?.map((item) => ({ label: item.school, value: item.school })))
    }

    loadOptions = (value, callback) => {
        this.setState({ noOptionLength: value });
        if (value === "") {
            this.setState({ highSchoolData: [] });
        }
        this.setState({ isload: true });
        let list = []
        if (value !== "") {
            schoolApi(value).then((res) => {
                res?.data?.data.map((item, index) => {
                    list.push({
                        label: item.school,
                        value: item.school
                    });

                });

                this.setState({ highSchoolData: list });
                this.setState({ isload: false });
            })
        }

    }


    loadUniversityOptions = (value, callback) => {
        // universityApi(inputText).then((res) => callback(res.data.data?.map((item) => ({ label: item.university, value: item.university }))));
        if (value === "") {
            this.setState({ universityData: [] });
        }
        this.setState({ isload: true });
        this.setState({ noOptionLength: value });
        let list = []
        if (value !== "") {
            universityApi(value).then((res) => {
                if (res.status === 200) {
                    res?.data?.data.map((item, index) => {
                        list.push({
                            label: item.university,
                            value: item.university
                        });

                    });
                    this.setState({ universityData: list });
                } else {
                    this.setState({ universityData: [] });
                }

                this.setState({ isload: false });
            })
        }

    }

    onTagsUnivChange = (event, values) => {
        if (values === "") {
            this.setState({ universityData: [] });
        }
        let { errors } = this.state;
        this.setState({ selectedUniversityOption: values });
        errors['university'] = ""
    }


    handleValidation() {
        let { selectedGenderOption, selectedZipOption, selectedStatusOption, selectedCountryOption, selectSchoolOption, selectedUniversityOption, selectGraduationOption, fields } = this.state;
        let errors = {};
        let formIsValid = true;

        let {
            studentsCollabCheckBox,
            counselingAllowedCheckBox,
            helpWithMilestonesCheckBox,
            subCouncelingServicesCheckBox
        } = this.refs;

        if (
            !studentsCollabCheckBox.checked &&
            !counselingAllowedCheckBox.checked &&
            !helpWithMilestonesCheckBox.checked &&
            !subCouncelingServicesCheckBox.checked
        ) {
            formIsValid = false;
            errors["checkboxes"] = "Select at least one checkbox";
        }

        if (typeof fields["firstName"] !== "undefined") {
            const textcaseRegExp = /^[A-Z a-z]+$/;
            const floatcasenumber = textcaseRegExp.test(fields["firstName"]);
            // if (!floatcasenumber) {
            //     formIsValid = false;
            //     errors["firstName"] = "FirstName should be Text";
            // }
            if (fields["firstName"].length < 2) {
                formIsValid = false;
                errors["firstName"] = "First name must contain 2 characters";
            }
            if (fields["firstName"].length > 50) {
                formIsValid = false;
                errors["firstName"] = "Max upto 50 characters";
            }
            if (!fields["firstName"]) {
                formIsValid = false;
                errors["firstName"] = "*Required";
            }
        }
        if (typeof fields["lastName"] !== "undefined") {
            const textcaseRegExp = /^[A-Z a-z]+$/;
            const floatcasenumber = textcaseRegExp.test(fields["lastName"]);
            // if (!floatcasenumber) {
            //     formIsValid = false;
            //     errors["lastName"] = "LastName should be Text";
            // }
            if (fields["lastName"].length < 1) {
                formIsValid = false;
                errors["lastName"] = "LastName must contain 1 characters";
            }
            if (fields["lastName"].length > 50) {
                formIsValid = false;
                errors["lastName"] = "Max upto 50 characters";
            }
            if (!fields["lastName"]) {
                formIsValid = false;
                errors["lastName"] = "*Required";
            }
        }
        if (selectedZipOption === undefined || selectedZipOption === null) {
            formIsValid = false;
            errors["zipCode"] = "*Required";
        }
        if (typeof selectedZipOption !== "undefined") {
            if (this.state.selectedCountryOption != null || undefined || "") {
                if (selectedCountryOption !== null && this.state.selectedCountryOption.value === "US") {
                    const maxnumbercaseRegExp = /^[0-9]{0,5}$/;
                    const minnumbercaseRegexp = /^.{5,}$/;
                    const maxcasenumber = maxnumbercaseRegExp.test(selectedZipOption);
                    const mincasenumber = minnumbercaseRegexp.test(selectedZipOption);
                    if (!maxcasenumber) { formIsValid = false; errors["zipCode"] = "Zipcode should be 5 digit"; }
                    if (!mincasenumber) { formIsValid = false; errors["zipCode"] = "Zipcode should be 5 digit"; }
                }
                else if (selectedCountryOption !== null && this.state.selectedCountryOption.value === "IN") {
                    const maxnumbercaseRegExp = /^[0-9]{0,6}$/;
                    const minnumbercaseRegexp = /^.{6,}$/;
                    const maxcasenumber = maxnumbercaseRegExp.test(selectedZipOption);
                    const mincasenumber = minnumbercaseRegexp.test(selectedZipOption);
                    if (!maxcasenumber) { formIsValid = false; errors["zipCode"] = "Zipcode should be 6 digit"; }
                    if (!mincasenumber) { formIsValid = false; errors["zipCode"] = "Zipcode should be 6 digit"; }
                }
            }
        }
        if (selectedGenderOption === null) {
            formIsValid = false;
            errors["gender"] = "*Required";
        }
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "*Required";
        }
        else if (fields["email"] !== "") {
            const textcaseRegExp = /^[\w-\.+]+@([\w-]+\.)+[\w-]{2,4}$/;
            const emailvalid = textcaseRegExp.test(fields["email"]);
            if (!emailvalid) {
                formIsValid = false;
                errors["email"] = "Email is invalid";
            }
            let lastAtPos = fields["email"]?.lastIndexOf("@");
            let lastDotPos = fields["email"]?.lastIndexOf(".");
            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    fields["email"].indexOf("@@") === -1 &&
                    lastDotPos > 2 &&
                    fields["email"].length - lastDotPos > 2
                )
            ) {
                formIsValid = false;
                errors["email"] = "Not a valid email";
            }
        }

        if (selectedStatusOption === null) {
            formIsValid = false;
            errors["status"] = "*Required";
        }
        if (selectGraduationOption === null) {
            formIsValid = false;
            errors["graduationYear"] = "*Required";
        }
        if (fields["currentGPA"]) {
            const decimalcaseRegExp = /^(?:[1-4](\.\d{1})?|5(\.0{1})?)$/;
            const digitcaseRegExp = (/^[1-5]$/);
            const floatcasenumber = decimalcaseRegExp.test(fields["currentGPA"]);
            const digitcasenumber = digitcaseRegExp.test(fields["currentGPA"]);
            if (!digitcasenumber && !floatcasenumber) {
                formIsValid = false;
                errors["currentGPA"] = "Invalid GPA";
            }
        }
        if (fields["unWeighted"]) {
            const decimalcaseRegExp = /^(?:[1-3](\.\d{1})?|4(\.0{1})?)$/;
            const digitcaseRegExp = (/^[1-4]$/);
            const floatcasenumber = decimalcaseRegExp.test(fields["unWeighted"]);
            const digitcasenumber = digitcaseRegExp.test(fields["unWeighted"]);
            if (!digitcasenumber && !floatcasenumber) {
                formIsValid = false;
                errors["unWeighted"] = "Invalid GPA";
            }
        }
        // if (this?.props?.parentGoal && this?.props?.parentGoal?.goals !== undefined) {
        //     let goalesData = this?.props?.parentGoal?.goals
        //     if (goalesData && goalesData?.length > 0 && goalesData?.includes('gpa')) {
        //         if (typeof fields["currentGPA"] !== "undefined") {
        //             const decimalcaseRegExp = (/^[1-9]\.\d{1,2}$/);
        //             const digitcaseRegExp = (/^[1-9]$/);
        //             const floatcasenumber = decimalcaseRegExp.test(fields["currentGPA"]);
        //             const digitcasenumber = digitcaseRegExp.test(fields["currentGPA"]);
        //             if (!digitcasenumber && !floatcasenumber) {
        //                 formIsValid = false;
        //                 errors["currentGPA"] = "Invalid GPA";
        //             }
        //         }
        //         if (!fields["currentGPA"]) {
        //             formIsValid = false;
        //             errors["currentGPA"] = "*Required";
        //         }
        //     } else if (fields["currentGPA"] !== "") {
        //         if (typeof fields["currentGPA"] !== "undefined") {
        //             const decimalcaseRegExp = (/^[1-9]\.\d{1,2}$/);
        //             const digitcaseRegExp = (/^[1-9]$/);
        //             const floatcasenumber = decimalcaseRegExp.test(fields["currentGPA"]);
        //             const digitcasenumber = digitcaseRegExp.test(fields["currentGPA"]);
        //             if (!digitcasenumber && !floatcasenumber) {
        //                 formIsValid = false;
        //                 errors["currentGPA"] = "Invalid GPA";
        //             }
        //         }
        //     }
        // }

        if (selectSchoolOption === null) {
            formIsValid = false;
            errors["highSchoolName"] = "*Required";
        }
        if (selectSchoolOption === undefined) {
            formIsValid = false;
            errors["highSchoolName"] = "*Required";
        }

        if (selectedCountryOption === null) {
            formIsValid = false;
            errors["country"] = "*Required";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    handleScroll = () => {
        window.scrollTo(0, 250)
    }
    handleSchoolApi = (props) => {
        this.props.getSchools({ "searchString": props })
    }
    onerrorlist = (value) => {
        if (value === "email") {
            // Ensure errorList is an object before attempting to set its properties
            if (typeof this.props.errorList === 'object') {
                this.props.errorList.email = "";
            } else {
                // If errorList is not an object, you might want to handle this case accordingly
                console.error("ErrorList is not an object");
            }
        }
    }

    redirect = () => {
        if (this.props?.parentProfile?.length >= 1) {
            this.props.navigate('/onboarding/profile');

        } else {
            this.props.navigate('/onboarding/profile');
        }
    }



    handleNext = (e) => {
        e.preventDefault();
        if (this.handleValidation()) {
            this.setState({ alertValue: true })
            this.setState({ alertVisible: true })
            let fields = this.state.fields;
            let id = this.state.childId
            let { selectedGenderOption, selectedZipOption, selectedStatusOption, selectedCountryOption, selectedUniversityOption, selectGraduationOption, selectSchoolOption, timeZone } = this.state;
            let { studentsCollabCheckBox, counselingAllowedCheckBox, subCouncelingServicesCheckBox, helpWithMilestonesCheckBox } = this.state;
            if (this.props.createNew) {         // createNew as a prop from Parent(My Profile) Connect child
                let values = {
                    firstName: fields["firstName"],
                    lastName: fields["lastName"],
                    email: fields["email"],
                    country: selectedCountryOption.value,
                    zipCode: selectedZipOption,
                    timeZone: timeZone ? timeZone.value : null,
                    gender: selectedGenderOption.label,
                    status: selectedStatusOption.label,
                    graduationYear: selectGraduationOption.label,
                    highSchoolName: selectSchoolOption?.label || selectSchoolOption,
                    counselingAllowed: false,
                    helpWithCoursesAndActivities: counselingAllowedCheckBox,
                    studentsCollab: studentsCollabCheckBox,
                    subCouncelingServices: subCouncelingServicesCheckBox,
                    helpWithMilestones: helpWithMilestonesCheckBox,
                    weighted: fields["currentGPA"] || null,
                    unweighted: fields["unWeighted"] || null,
                }
                this.props.visible()             //  Hide Parent conn child( My Profile ) Popup
                this.props.setChildProfile(values);
                this.props.setIsChildProfileSuccess(true);
                // this.props.navigate('/onboarding/intialplansParent');
            } else {
                if (this.props.childprofile !== undefined && this.props.childprofile.length > 0) {
                    let values = {
                        studentId: id,
                        firstName: fields["firstName"],
                        lastName: fields["lastName"],
                        email: fields["email"],
                        country: selectedCountryOption.value,
                        zipCode: selectedZipOption,
                        gender: selectedGenderOption.label,
                        status: selectedStatusOption.label,
                        timeZone: timeZone ? timeZone.value : null,
                        graduationYear: selectGraduationOption.label,
                        highSchoolName: selectSchoolOption?.label || selectSchoolOption,
                        counselingAllowed: false,
                        helpWithCoursesAndActivities: counselingAllowedCheckBox,
                        studentsCollab: studentsCollabCheckBox,
                        subCouncelingServices: subCouncelingServicesCheckBox,
                        helpWithMilestones: helpWithMilestonesCheckBox,
                        weighted: fields["currentGPA"] || null,
                        unweighted: fields["unWeighted"] || null,
                    }

                    this.props.editChildProfile(values);
                    // this.props.navigate('/onboarding/intialplansParent');

                }
                else {
                    let values = {
                        firstName: fields["firstName"],
                        lastName: fields["lastName"],
                        email: fields["email"],
                        country: selectedCountryOption.value,
                        zipCode: selectedZipOption,
                        gender: selectedGenderOption.label,
                        status: selectedStatusOption.label,
                        timeZone: timeZone ? timeZone.value : null,
                        graduationYear: selectGraduationOption.label,
                        currentGpa: fields["currentGPA"] || null,
                        highSchoolName: selectSchoolOption?.label || selectSchoolOption,
                        counselingAllowed: false,
                        helpWithCoursesAndActivities: counselingAllowedCheckBox,
                        studentsCollab: studentsCollabCheckBox,
                        subCouncelingServices: subCouncelingServicesCheckBox,
                        helpWithMilestones: helpWithMilestonesCheckBox,
                        weighted: fields["currentGPA"] || null,
                        unweighted: fields["unWeighted"] || null,
                    }
                    this.props.setChildProfile(values);
                    // this.props.navigate('/onboarding/intialplansParent');
                }
            }
        }
    };

    onTagsChange = (event, values) => {
        let { errors } = this.state;
        if (values === null) {
            this.setState({ selectSchoolOption: null });
        } else {
            this.setState({ selectSchoolOption: values });

        }
        errors["highSchoolName"] = "";
    }


    UniversityloadOptions = (value, callback) => {
        if (value === "") {
            this.setState({ highSchoolData: [] });
        }
        this.setState({ isload: true });
        let list = []
        universityApi(value).then((res) => {
            res?.data?.data.map((item, index) => {
                list.push({
                    label: item.university,
                    value: item.university
                });

            });

            this.setState({ highSchoolData: list });
            this.setState({ isload: false });
        })
    }

    handleTimeZoneChange = (selectedOption) => {
        let { errors } = this.state;
        this.setState({ timeZone: selectedOption });
        errors["timeZone"] = "";
    }


    render() {
        const { gender, status, country, universities, graduationyear, userProfile } = this.props;
        const { highschoollist, selectedGenderOption, errorList, selectedZipOption } = this.state;
        const { selectedStatusOption, selectedCountryOption, selectSchoolOption, selectGraduationOption, selectedUniversityOption } = this.state;
        const { studentsCollabCheckBox, counselingAllowedCheckBox, helpWithMilestonesCheckBox, subCouncelingServicesCheckBox, } = this.state;
        const Dates = Array.from({ length: 10 }, (_, i) => new Date().getFullYear() + i);
        let goalesData = this?.props?.parentGoal?.goals
        let genderOptions = [];
        let statusOptions = [];
        let countryOptions = [];
        let graduationOptions = [];
        let universityOptionstions = [];
        let SchoolOptions = [];
        gender !== undefined && gender.length > 0 && gender.map((x, key) => {
            var temp = { label: x, value: key }
            genderOptions.push(temp);
        });
        status !== undefined && status.length > 0 && status.filter((x) => x.role !== "professional").map((x, key) => {
            var temp = { label: x.status, value: x.id, role: x.role }
            statusOptions.push(temp);
        });
        country !== undefined && country.length > 0 && country.map((x, key) => {
            var temp = { label: x.countryName, value: x.isoCode2Digit }
            countryOptions.push(temp);
        })
        graduationyear !== undefined && graduationyear.length > 0 && graduationyear.map((x, key) => {
            var temp = { label: x, value: x }
            graduationOptions.push(temp);
        })
        highschoollist !== undefined && highschoollist.length > 0 && highschoollist.map((x, key) => {
            var temp = { label: x.school, value: x.school }
            SchoolOptions.push(temp);
        })
        universities !== undefined && universities.length > 0 && universities.map((university, key) => {
            var temp = { label: university.university, value: university.universities }
            universityOptionstions.push(temp);
        });

        console.log(this.props.getstudentplan, "this.props.getstudentplan")


        return (
            <>
                {this.state.loading && <Loader />}
                {this.state.loading || this.props.loading && <Loader />}

                <div className="profile">
                    {/* <div className="d-flex">
                        <SchoolOutlinedIcon className="studentIcon" sx={{ fontSize: '2rem', marginTop: "13px", marginRight: '10px' }} />

                        <div className=" childtitle" style={{ "display": "flex", }}><h1>Create Student Profile</h1></div>
                    </div> */}
                    <h1 className="profile__form__title text-center mb-2 mt-3">
                        <span style={{ "marginRight": "10px" }} >
                            <svg width="48" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.57517 12.3301L2.95169 10.8807C2.60805 10.7432 2.60805 10.2568 2.95169 10.1193L11.2572 6.79711C11.734 6.60638 12.266 6.60638 12.7428 6.79711L21.0483 10.1193C21.392 10.2568 21.392 10.7432 21.0483 10.8807L17.4248 12.3301" stroke="#222222" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M14 13.5C14 13.7761 14.2239 14 14.5 14C14.7761 14 15 13.7761 15 13.5H14ZM14 7.5V13.5H15V7.5H14Z" fill="#222222" />
                                <path d="M6.5 12.5V16.5L12 18.5L17.5 16.5V12.5C17.5 12.5 17 10.5 12 10.5C7 10.5 6.5 12.5 6.5 12.5Z" stroke="#222222" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </span>
                        Create Student Profile</h1>

                    <div className="d-flex justify-content-center" style={{ position: 'relative' }}>
                        {Object.keys(errorList)?.length === 0 && <CustomAlert />}
                    </div>
                    <div className="profile__form">
                        {/* <p className="profile__form__sub-title">General</p> */}

                        <Form >
                            <div className="row m-0">
                                <div className="col-md-6 col-sm-6 col-12 ps-0 p-mob-0">
                                    <div className="input-floating-label">
                                        <input refs="firstName" type="text" className={"textbox--primary textbox--rounded input"} value={this.state.fields["firstName"]} name="firstName" placeholder=" " onChange={this.handleChange.bind(this, "firstName")} />
                                        <label>{FIRST_NAME_LABEL}</label>
                                        {errorList?.firstName && <span className="error-text">{this.state?.errorList?.firstName}</span>}
                                        {this.state.errors["firstName"] && <span className="error-text ps-0">{this.state.errors["firstName"]}</span>}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-12 pe-0 p-mob-0">
                                    <div className="input-floating-label">
                                        <input refs="lastName" type="text" className={"textbox--primary textbox--rounded input"} name="lastName" placeholder=" " onChange={this.handleChange.bind(this, "lastName")} value={this.state.fields["lastName"]} />
                                        <label>{LAST_NAME_LABEL}</label>
                                        {this.state.errors["lastName"] && <span className="error-text ps-0">{this.state.errors["lastName"]}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-md-6 col-sm-6 col-12 ps-0 p-mob-0">
                                    <div className="input-floating-label">
                                        <Select value={selectedCountryOption} options={countryOptions} placeholder={COUNTRY_NAME_LABEL} className="goals__form__select mb-0" classNamePrefix="mySelect" name="country" closeMenuOnSelect={true} isClearable={false} onChange={(value) => this.handleSelectChange(value, 'country')} components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }} styles={{ container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) }} />
                                        {this.state.errors["country"] && <span className="error-text ps-0">{this.state.errors["country"]}</span>}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-12 pe-0 p-mob-0">

                                    {/* <div className="input-floating-label">
                                        <input refs="zipCode" type="text" className={"textbox--primary textbox--rounded input"} value={this.state.fields["zipCode"]} name="zipCode" placeholder=" "
                                            onChange={this.handleChange.bind(this, "zipCode")} maxLength={selectedCountryOption?.value === "US" ? 5 : 6} onKeyPress={(event) => { if (!/[0-9-]/.test(event.key)) { event.preventDefault(); } }} />
                                        <label>{ZIP_CODE_LABEL}</label>
                                        {this.state.errors["zipCode"] && <span className="error-text ps-0">{this.state.errors["zipCode"]}</span>}
                                        {errorList && errorList.zipCode && <span className="error-text">{errorList.zipCode}</span>}
                                    </div> */}
                                    <Autocomplete
                                        noOptionsText={'There is no university related to your search'}
                                        className="goals__form__select"
                                        id="tags-filled"
                                        name="zipCode"
                                        type='text'
                                        clearOnBlur={false}
                                        filterSelectedOptions
                                        forcePopupIcon={false}
                                        freeSolo={true}
                                        openOnFocus={true}
                                        options={this.state.zipCodeOptions?.length > 0 ? this.state.zipCodeOptions.map((option) => option.label) : []}
                                        filterOptions={(options, state) => options}
                                        value={selectedZipOption !== null ? selectedZipOption : []}
                                        onChange={this.onTagsZipChange}
                                        onInputChange={async (event, value) => { this.zipLoadOptions(value) }}
                                        renderInput={(params) => (
                                            <div className="input-floating-label" ref={params.InputProps.ref} style={{ marginBottom: '20px' }}>
                                                <input type="text" {...params.inputProps} className={"textbox--primary textbox--rounded input"} id="zipCode" name="zipCode" placeholder=" " />
                                                <label>{ZIP_CODE_LABEL}</label>
                                                {this.state.errors["zipCode"] && <span className="error-text ps-0">{this.state.errors["zipCode"]}</span>}
                                           
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-md-12 col-sm-12 col-12 p-0">
                                    <div className="input-floating-label">
                                        <Select value={this.state.timeZone} options={timezonesOptions} placeholder="Time Zone" className="goals__form__select mb-0" classNamePrefix="mySelect" name="country" closeMenuOnSelect={true} isClearable={false} onChange={(value) => this.handleTimeZoneChange(value)} components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }} styles={{ container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) }} />
                                        {this.state.errors["timeZone"] && <span className="error-text ps-0">{this.state.errors["timeZone"]}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-md-12 col-sm-12 col-12 p-0">
                                    <div className="input-floating-label">
                                        <Select className="goals__form__select mb-0" classNamePrefix="mySelect" name="gender" options={genderOptions} closeMenuOnSelect={true} isClearable={false} onChange={(value) => this.handleSelectChange(value, 'gender')} placeholder={GENDER_LABEL} value={selectedGenderOption} components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }} styles={{ container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) }} />
                                        {this.state.errors["gender"] && <span className="error-text ps-0">{this.state.errors["gender"]}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-md-12 col-sm-12 col-12 p-0">
                                    <div className="input-floating-label">
                                        <Select onFocus={(e) => this.handleScroll(e)} isDisabled={this.props?.childprofile?.length > 0 && this.props?.childprofile[0]?.studentProfile?.myStatus !== null} className="goals__form__select mb-0" classNamePrefix="mySelect" name="status" options={statusOptions} closeMenuOnSelect={true} isClearable={false} onChange={(value) => this.handleSelectChange(value, 'status')} value={selectedStatusOption} components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }} placeholder={"Child's Status"} styles={{ option: (provided, state) => ({ ...provided, cursor: 'pointer', }), container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10 }) }} />
                                        {this.state.errors["status"] && <span className="error-text ps-0">{this.state.errors["status"]}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-md-12 col-sm-12 col-12 p-0">
                                    <div className="input-floating-label">
                                        <Select className="goals__form__select " classNamePrefix="mySelect" name="graduationYear" options={graduationOptions} closeMenuOnSelect={true} isClearable={false} onChange={(value) => this.handleSelectChange(value, 'graduationYear')} value={selectGraduationOption} components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }} placeholder={GRADUATION_YEAR_LABEL} styles={{ container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10 }) }} />
                                        {this.state.errors["graduationYear"] && <span className="error-text ps-0">{this.state.errors["graduationYear"]}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0">

                                {
                                    selectedStatusOption?.role === "university" ? (
                                        // <div className="col-md-12 col-sm-12 col-12 p-0 multinew" style={{ marginBottom: "26px" }} >
                                        //     <Autocomplete

                                        //         className="goals__form__select"
                                        //         id="tags-filled"
                                        //         name="highschoolDropdown"
                                        //         type='text'
                                        //         clearOnBlur={false}
                                        //         clearIcon
                                        //         freeSolo={true}
                                        //         options={this.state.universityData?.length > 0 ? this.state.universityData.map((option) => option?.label) : []}
                                        //         filterOptions={(options, state) => options}
                                        //         value={selectedUniversityOption?.label || selectedUniversityOption || ""}
                                        //         onChange={this.onTagsUnivChange}
                                        //         onInputChange={async (event, value) => {
                                        //             this.loadUniversityOptions(value)
                                        //         }}
                                        //         renderInput={(params) => (
                                        //             <>
                                        //                 <div className="input-floating-label" ref={params.InputProps.ref} style={{ marginBottom: '4px' }}>
                                        //                     <input type="text" {...params.inputProps} className={"textbox--primary textbox--rounded input"} name="email" placeholder=" " />
                                        //                     <label>{"University Name"}</label>
                                        //                 </div>

                                        //             </>
                                        //         )}
                                        //     />
                                        //     {this.state.errors["highSchoolName"] && <span className="error-text ps-0">{this.state.errors["highSchoolName"]}</span>}
                                        // </div>
                                        <div className="col-md-12 col-sm-12 col-12 p-0 asyncmultiselect multinew" style={{ marginBottom: "26px" }}>
                                            <Autocomplete className="goals__form__select" id="Universitydropdown" name="Universitydropdown" type='text'
                                                freeSolo clearOnBlur={false} clearIcon={false} noOptionsText={'There is no school related to your search'}
                                                options={this.state.highSchoolData?.length > 0 ? this.state.highSchoolData.map((option) => option.label) : []}
                                                filterOptions={(options, state) => options} value={selectSchoolOption?.label || selectSchoolOption}
                                                onChange={this.onTagsChange} onInputChange={async (event, value) => { this.UniversityloadOptions(value) }}
                                                renderInput={(params) => (
                                                    <div className="input-floating-label" ref={params.InputProps.ref} style={{ marginBottom: '4px' }}>
                                                        <input type="text" {...params.inputProps} className={"textbox--primary textbox--rounded input"} name="email" placeholder=" " />
                                                        <label>{"University Name"}</label>
                                                    </div>
                                                )}
                                            />
                                            {this.state.errors["highSchoolName"] && <span className="error-text ps-0">{this.state.errors["highSchoolName"]}</span>}

                                        </div>
                                    ) : (
                                        <div className="col-md-12 col-sm-12 col-12 p-0 multinew" style={{ marginBottom: "26px" }} >
                                            <Autocomplete
                                                className="goals__form__select"
                                                id="tags-filled"
                                                name="highschoolDropdown"
                                                type='text'
                                                freeSolo
                                                clearOnBlur={false}
                                                clearIcon={false}
                                                noOptionsText={'There is no school related to your search'}
                                                options={this.state.highSchoolData?.length > 0 ? this.state.highSchoolData.map((option) => option.label) : []}
                                                filterOptions={(options, state) => options}
                                                value={selectSchoolOption?.label || selectSchoolOption}
                                                onChange={this.onTagsChange}
                                                onInputChange={async (event, value) => {
                                                    this.loadOptions(value)
                                                }}
                                                renderInput={(params) => (
                                                    <>
                                                        <div className="input-floating-label" ref={params.InputProps.ref} style={{ marginBottom: '4px' }}>
                                                            <input type="text" {...params.inputProps} className={"textbox--primary textbox--rounded input"} name="email" placeholder=" " />
                                                            <label>{"High School Name"}</label>
                                                        </div>
                                                    </>
                                                )}
                                            />
                                            {this.state.errors["highSchoolName"] && <span className="error-text ps-0">{this.state.errors["highSchoolName"]}</span>}
                                        </div>
                                    )
                                }

                            </div>

                            <div className="row m-0">
                                <div className="col-md-6 col-sm-6 col-12 ps-0 p-mob-0">
                                    <div className="input-floating-label">
                                        <input refs="currentGPA" onKeyPress={(event) => { if (!/[0-9.]/.test(event.key)) { event.preventDefault(); } }} type="text" className={"textbox--primary textbox--rounded input"} name="currentGPA" placeholder={GPA_LABEL} onChange={this.handleChange.bind(this, "currentGPA")} value={this.state.fields["currentGPA"]} />
                                        <label>{"Weighted GPA"}</label>
                                        {!goalesData?.includes('gpa') &&
                                            <p className="cta--text mb-2" style={{ "fontSize": "12px", "marginLeft": "20px" }}>Optional</p>
                                        }
                                        {errorList?.weighted && <span className="error-text">{this.state?.errorList?.weighted}</span>}
                                        {this.state.errors["currentGPA"] && <span className="error-text ps-0">{this.state.errors["currentGPA"]}</span>}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-12 pe-0 p-mob-0">
                                    <div className="input-floating-label">
                                        <input refs="unWeighted" onKeyPress={(event) => { if (!/[0-9.]/.test(event.key)) { event.preventDefault(); } }} type="text" className={"textbox--primary textbox--rounded input"} name="unWeighted" placeholder={GPA_LABEL} onChange={this.handleChange.bind(this, "unWeighted")} value={this.state.fields["unWeighted"]} />
                                        <label>{"Unweighted GPA"}</label>
                                        <p className="cta--text mb-2" style={{ "fontSize": "12px", "marginLeft": "20px" }}>Optional</p>
                                        {errorList?.unweighted && <span className="error-text">{this.state?.errorList?.unweighted}</span>}
                                        {this.state.errors["unWeighted"] && <span className="error-text ps-0">{this.state.errors["unWeighted"]}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-md-12 col-sm-12 col-12 p-0">
                                    <div className="input-floating-label">
                                        <input refs="email" type="email" className={"textbox--primary textbox--rounded input"} name="email" placeholder=" " onChange={this.handleChange.bind(this, "email")} value={this.state.fields["email"]} onClick={() => this.onerrorlist("email")} />
                                        <label>{"Child’s Email"}</label>
                                        {this?.state?.errors["email"] && <span className="error-text ps-0">{this?.state?.errors["email"]}</span>}
                                        {errorList?.email && <span className="error-text">{this.state?.errorList?.email}</span>}
                                        {/* {this.state.alertValue && <CustomAlert />} */}

                                    </div>
                                </div>

                            </div>
                            <div style={{ "marginBottom": "100px" }}>
                                <p className="profile__form__sub-title">I need help with</p>
                                <p className="">
                                    <input ref='studentsCollabCheckBox' defaultChecked={studentsCollabCheckBox} className="styled-checkbox" id="styled-checkbox-1" type="checkbox" value="value2" onClick={(e) => this.handleCheckboxChange(e, 'studentsCollab')} />
                                    <label htmlFor="styled-checkbox-1">
                                        <span>Collaborating with students with similar goals</span>
                                    </label>
                                </p>
                                <p className="">
                                    <input ref='counselingAllowedCheckBox' defaultChecked={counselingAllowedCheckBox} className="styled-checkbox" id="styled-checkbox-2" type="checkbox" value="value2" onClick={(e) => this.handleCheckboxChange(e, 'counselingAllowed')} />
                                    <label htmlFor="styled-checkbox-2">
                                        <span>Selecting courses/activities to meet my goal</span>
                                    </label>
                                </p>
                                <p className="">
                                    <input ref="helpWithMilestonesCheckBox" defaultChecked={helpWithMilestonesCheckBox}
                                        className="styled-checkbox"
                                        id="styled-checkbox-4"
                                        type="checkbox"
                                        value="value2"
                                        onClick={(e) => this.handleCheckboxChange(e, 'helpWithMilestones')}
                                    />
                                    <label htmlFor="styled-checkbox-4">
                                        <span>Help with course/activity milestones through school year</span>
                                        <Tooltip title="Assignments, homework, test prep etc" placement="right">

                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.00008 7.16699C8.27622 7.16699 8.50008 7.39085 8.50008 7.66699V11.0003C8.50008 11.2765 8.27622 11.5003 8.00008 11.5003C7.72394 11.5003 7.50008 11.2765 7.50008 11.0003V7.66699C7.50008 7.39085 7.72394 7.16699 8.00008 7.16699Z" fill="#919293" />
                                                <path d="M8.00008 6.00033C8.36827 6.00033 8.66675 5.70185 8.66675 5.33366C8.66675 4.96547 8.36827 4.66699 8.00008 4.66699C7.63189 4.66699 7.33341 4.96547 7.33341 5.33366C7.33341 5.70185 7.63189 6.00033 8.00008 6.00033Z" fill="#919293" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M2.16675 8.00033C2.16675 4.77866 4.77842 2.16699 8.00008 2.16699C11.2217 2.16699 13.8334 4.77866 13.8334 8.00033C13.8334 11.222 11.2217 13.8337 8.00008 13.8337C4.77842 13.8337 2.16675 11.222 2.16675 8.00033ZM8.00008 3.16699C5.3307 3.16699 3.16675 5.33095 3.16675 8.00033C3.16675 10.6697 5.3307 12.8337 8.00008 12.8337C10.6695 12.8337 12.8334 10.6697 12.8334 8.00033C12.8334 5.33095 10.6695 3.16699 8.00008 3.16699Z" fill="#919293" />
                                            </svg>
                                        </Tooltip>
                                    </label>
                                </p>
                                <p className="">
                                    <input ref='subCouncelingServicesCheckBox' defaultChecked={subCouncelingServicesCheckBox} className="styled-checkbox" id="styled-checkbox-3" type="checkbox" value="value2" onClick={(e) => this.handleCheckboxChange(e, 'subCouncelingServices')} />
                                    <label htmlFor="styled-checkbox-3">
                                        <span>Professional counseling services</span>
                                    </label>
                                </p>

                                {!this.props.errorList && this.state.alertValue &&
                                    <CustomAlert />
                                }
                                {this.state.errors["checkboxes"] && <span className="error-text ps-0">{this.state.errors["checkboxes"]}</span>}

                            </div>

                        </Form>
                        {this.props.createNew &&    // createNew(*prop) -> Parent Connect Child
                            <>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => this.props.visible()}>
                                        Close
                                    </Button>
                                    <Button style={{ backgroundColor: "#0d6efd" }} onClick={e => this.handleNext(e)}> Create Child </Button>
                                </Modal.Footer>
                            </>
                        }
                    </div>
                </div>

                <Footer reverseLink={"/onboarding/profile/parent"}>
                    <Link className="footers__rightSec__cta" to="#" onClick={(e) => this.handleNext(e)} >Set up a plan
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.92893 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.0222 21.4135 8.08879 20.3147 6.4443C19.2159 4.79981 17.6541 3.51808 15.8268 2.76121C13.9996 2.00433 11.9889 1.8063 10.0491 2.19215C8.10929 2.578 6.32746 3.53041 4.92893 4.92893" stroke-width="2" className="rolestrokes" />
                            <path d="M15 12L15.7809 11.3753L16.2806 12L15.7809 12.6247L15 12ZM3 13C2.44771 13 2 12.5523 2 12C2 11.4477 2.44771 11 3 11V13ZM11.7809 6.3753L15.7809 11.3753L14.2191 12.6247L10.2191 7.6247L11.7809 6.3753ZM15.7809 12.6247L11.7809 17.6247L10.2191 16.3753L14.2191 11.3753L15.7809 12.6247ZM15 13H3V11H15V13Z" className="rolefills" />
                        </svg>
                    </Link>
                </Footer>
            </>
        );
    }
}
const mapStateToProps = ({ commonData, userProfileData, parentData, studentData }) => {
    const { message, errorList, status, gender, country, schools, loading, graduationyear } = commonData
    const { isChildProfileSucess, userProfile, childprofile, getchildstatus, editChildProfileSucess, parentProfile } = userProfileData;
    const { parentGoal, isParentGoalSuccess, isParentGoalStatus, } = parentData;
    const { isGoalSuccess, getstudentplan } = studentData;
    return { message, errorList, status, isGoalSuccess, getchildstatus, getstudentplan, isParentGoalSuccess, parentProfile, editChildProfileSucess, isParentGoalStatus, schools, gender, parentGoal, isChildProfileSucess, userProfile, country, loading, childprofile, graduationyear }
};
export default connect(mapStateToProps, { getGender, getStatus, clearErrorList, GetStudentPlan, setChildProfile, getParentProfile, editIsChildProfileSuccess, getGoalsFromStudent, getParentGoals, getChildProfilestatus, setIsParentGoalSuccess, getGoalsFromStudentStatus, setIsGoalSuccess, editChildProfile, getUserProfile, setIsUserProfileSuccess, setIsChildProfileSuccess, getCountry, getSchools, getChildProfile, clearMessage })(withRouter(Index));