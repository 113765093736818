import React, { Component } from "react";
import "../../../../assets/css/profile.less";
import { Link } from "react-router-dom";
import Select, { components } from "react-select";
import { connect } from "react-redux";
import { getGender, getStatus, getCountry, updateUserProfile, getUserProfile, setIsGoalSuccess, getSchools, clearMessage, getUserGoals, clearErrorList, getUserProfilestatus, ConnectParent } from "../../../../redux/actions";
import { withRouter } from "../../../../redux/store/navigate";
import { Form } from "react-bootstrap";
import Loader from "../../../../components/Loader";
import { COUNTRY_NAME_LABEL, FIRST_NAME_LABEL, EMAIL_LABEL, GENDER_LABEL, GPA_LABEL, GRADUATION_YEAR_LABEL, High_SCHOOL_LABEL, LAST_NAME_LABEL, MY_STATUS_LABEL, ZIP_CODE_LABEL } from "../../../../Constants/commonLabels";
import { schoolApi, universityApi,loadZipcode } from "../../../../utils/AutoComplete";
import Autocomplete from '@mui/material/Autocomplete';
import CustomAlert from "../../../../components/CustomAlert"
import { Tooltip } from "@mui/material";
import Footer from '../../../../componentsNew/Common/Footer'

const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder}</Placeholder>
            {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
        </ValueContainer>
    );
};
const timezonesOptions = [
    { label: 'Pacific Time', value: 'America/Los_Angeles' },
    { label: 'Eastern Time', value: 'America/New_York' },
    { label: 'Central Time', value: 'America/Chicago' },
    { label: 'Mountain Standard Time', value: 'America/Phoenix' },
    { label: 'Mountain Daylight Time', value: 'America/Denver' },
    { label: 'Hawaii Time', value: 'Pacific/Honolulu' },
    { label: 'Alaska Time', value: 'America/Anchorage' },
];
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: false,
            loading: false,
            fields: { firstName: "", lastName: "" },
            errors: {},
            formData: {
                zipCode: ''
            },
            zipCodeOptions: [],
            selectedZipOption: null,
            status: false,
            selectedGenderOption: null,
            selectedStatusOption: null,
            selectedCountryOption: null,
            studentsCollabCheckBox: false,
            counselingAllowedCheckBox: false,
            helpWithMilestonesCheckBox: false,
            subCouncelingServicesCheckBox: false,
            selectGraduationOption: null,
            selectSchoolOption: null,
            selectTimezoneOption: null,
            highSchoolData: [],
            isload: false,
            errorList: [],
            statusrole: "highschool"
        };
    }

    componentDidMount() {
        this.setState({ loading: true })
        this.props.setIsGoalSuccess(false)
        this.props.getUserProfile()
        this.props.clearMessage()
        this.props.clearErrorList()
    }

    componentDidUpdate() {
        if (!this.state.status) {
            if (this.props.status !== undefined && this.props.status.length > 0) {
                this.setState({ loading: false, status: true })
            }
        }
        this.setInitialValues();
        if (this.props.isProfileSuccess) {
            this.props.navigate('/onboarding/plans');
        }
        if (this.props.getprofilestatus) {
            this.setState({ dataLoaded: false })
            this.setInitialValues();
            this.props.getUserProfilestatus(false)
        }
    }
    componentWillReceiveProps(newProps) {
        this.setState({
            highschoollist: newProps.schools,
        });
    }
    componentWillReceiveProps(newProps) {
        this.setState({
            errorList: newProps.errorList,
        });
    }
    loadSchoolOptions = async (inputText, callback) => {
        let { highschoollist } = this.state;
        if ("inputText" !== "") {
            this.props.getSchools({ "searchString": inputText })
            callback(highschoollist?.map((item) => ({ label: item.school, value: item.school })))
        }
    }
    loadOptions = (value, callback) => {
        if (value === "") {
            this.setState({ highSchoolData: [] });
        }
        this.setState({ isload: true });
        let list = []
        if (value !== "") {
            schoolApi(value).then((res) => {
                res?.data?.data.map((item, index) => {
                    list.push({
                        label: item.school,
                        value: item.school
                    });

                });

                this.setState({ highSchoolData: list });
                this.setState({ isload: false });
            })
        }
    }
    ziploadOptions = (value, callback) => {
        if (value === "") {
            this.setState({ universityData: [] });
        }
        this.setState({ isload: true });
        this.setState({ noOptionLength: value });
        let list = []
        if (value !== "") {
            this.setState({selectedZipOption : value})
            let payload = {
                searchString: value,
                pageNo: 1,
                limit: 80,
            };
            loadZipcode(payload).then((res) => {
                if (res.status === 200) {
                    res?.data?.data.map((item, index) => {
                        list.push({
                            label: item.zip,
                            value: item.zip
                        });

                    });
                    this.setState({ zipCodeOptions: list });
                } else {
                    this.setState({ zipCodeOptions: [] });
                }

                this.setState({ isload: false });
            })
        }

    }
    UniversityloadOptions = (value, callback) => {
        if (value === "") {
            this.setState({ highSchoolData: [] });
        }
        this.setState({ isload: true });
        let list = []
        if (value !== "") {
            universityApi(value).then((res) => {
                res?.data?.data.map((item, index) => {
                    list.push({
                        label: item.university,
                        value: item.university
                    });

                });

                this.setState({ highSchoolData: list });
                this.setState({ isload: false });
            })
        }
    }
    setInitialValues() {
        if (!this.state.dataLoaded) {
            let { fields, selectedGenderOption, selectedStatusOption, selectedCountryOption, selectSchoolOption, selectTimezoneOption,
                selectGraduationOption, studentsCollabCheckBox, counselingAllowedCheckBox, subCouncelingServicesCheckBox, helpWithMilestonesCheckBox } = this.state;
            const { gender, status, country, graduationyear } = this.props;
            let userProfile = null;
            if (this.state.fields["email"] === "") {
                this.state.fields["email"] = "";
            } else if (this.props?.userProfile && this.props?.userProfile?.email && !this.state.fields["email"]) {
                userProfile = this.props.userProfile;
                fields["email"] = userProfile.email ? userProfile.email : "";
            }
            if (this.props.userProfile && this.props.userProfile.firstName) {
                userProfile = this.props.userProfile;
                fields["firstName"] = userProfile.firstName ? userProfile.firstName : "";
                fields["lastName"] = userProfile.lastName ? userProfile.lastName : "";
            }
            selectTimezoneOption = timezonesOptions.map((zoneItem) => { return { label: zoneItem.label, value: zoneItem.value } }
            ).find((x) => x.value === this.props?.userProfile?.timezone)
            if (this.props.userProfile && this.props.userProfile && this.props.userProfile.studentProfile && this.props.userProfile.firstName && gender != undefined && status != undefined && country != undefined && graduationyear != undefined) {
                let userProfileData = this.props.userProfile.studentProfile;
                this.setState({ selectedZipOption: userProfile.zipCode })
                fields["zipCode"] = userProfile.zipCode ? userProfile.zipCode : "";
                fields["currentGPA"] = userProfileData.weighted ? userProfileData.weighted : "";
                fields["unWeighted"] = userProfileData.unweighted ? userProfileData.unweighted : "";

                selectedCountryOption = country.map((countryItem) => { return { label: countryItem.countryName, value: countryItem.isoCode2Digit } }
                ).find((x) => x.value === userProfile.country)

                selectedGenderOption = gender.map((genderItem) => { return { label: genderItem, value: genderItem } }
                ).find((x) => x.value === userProfile.gender)

                selectedStatusOption = status.map((statusItem) => { return { label: statusItem.status, value: statusItem.status, role: statusItem.role } }
                ).find((x) => x.value === userProfileData.myStatus)

                selectGraduationOption = graduationyear.map((gradItem) => { return { label: gradItem, value: gradItem } }
                ).find((x) => x.value === userProfileData.graduationYear)

                if (userProfileData.currrentSchoolName !== null || undefined || "") {
                    selectSchoolOption = { label: userProfileData.currrentSchoolName, value: userProfileData.currrentSchoolName }
                }

                if (userProfileData?.studentsCollab === true) {
                    studentsCollabCheckBox = true;
                    this.refs.studentsCollabCheckBox.checked = true;
                }
                if (userProfileData?.helpWithCoursesAndActivities === true) {
                    counselingAllowedCheckBox = true;
                    this.refs.counselingAllowedCheckBox.checked = true;
                }
                if (userProfileData?.helpWithMilestones === true) {
                    helpWithMilestonesCheckBox = true
                    this.refs.helpWithMilestonesCheckBox.checked = true;
                }
                if (userProfileData?.subCouncellingServices === true) {
                    subCouncelingServicesCheckBox = true;
                    this.refs.subCouncelingServicesCheckBox.checked = true;
                }
                this.setState({
                    selectedGenderOption, selectedStatusOption, selectedCountryOption, selectSchoolOption, selectTimezoneOption,
                    selectGraduationOption, studentsCollabCheckBox, counselingAllowedCheckBox, subCouncelingServicesCheckBox, helpWithMilestonesCheckBox
                });
                this.setState({ dataLoaded: true, loading: false })
            }
        }
    }

    handleChange(field, e) {
        this.props.clearErrorList()
        this.props.clearMessage()
        let { errors } = this.state;
        let fields = this.state.fields;
        fields[field] = e.target.value;
        if (e.target.value.length >= 0) {
            errors[field] = "";
        }
        this.setState({ fields, errors: errors });
    }

    handleCheckboxChange(e, buttonName) {
        this.state.errors.checkboxes = ""
        let { studentsCollabCheckBox, counselingAllowedCheckBox, subCouncelingServicesCheckBox, helpWithMilestonesCheckBox } = this.state;
        if (buttonName === "studentsCollab") {
            studentsCollabCheckBox = e.target.checked
        } else if (buttonName === "counselingAllowed") {
            counselingAllowedCheckBox = e.target.checked
        } else if (buttonName === "subCouncelingServices") {
            subCouncelingServicesCheckBox = e.target.checked
        } else if (buttonName === "helpWithMilestones") {
            helpWithMilestonesCheckBox = e.target.checked
        }

        this.setState({ studentsCollabCheckBox, counselingAllowedCheckBox, subCouncelingServicesCheckBox, helpWithMilestonesCheckBox })
    }

    handleSelectChange(options, name) {
        let { selectedGenderOption, selectedStatusOption, selectedCountryOption, selectGraduationOption, selectSchoolOption, statusrole, selectTimezoneOption, errors } = this.state;
        switch (name) {
            case "gender":
                selectedGenderOption = options;
                errors["gender"] = "";
                break;
            case "status":
                selectedStatusOption = options;
                statusrole = options.role;
                selectSchoolOption = undefined;
                errors["status"] = "";
                errors["highSchoolName"] = "";
                this?.UniversityloadOptions("")
                this?.loadOptions("")
                break;
            case "country":
                selectedCountryOption = options;
                errors["country"] = "";
                break;
            case "graduationYear":
                selectGraduationOption = options;
                errors["graduationYear"] = "";
                break;
            case "highSchoolName":
                selectSchoolOption = options;
                errors["highSchoolName"] = "";
                break;
            case "timezone":
                selectTimezoneOption = options;
                break;
        }
        this.setState({ selectedGenderOption, selectedStatusOption, selectedCountryOption, selectGraduationOption, selectSchoolOption, statusrole, selectTimezoneOption, errors: errors });
    }

    handleValidation() {
        let { selectedGenderOption, selectedStatusOption,selectedZipOption, selectedCountryOption, selectSchoolOption, selectGraduationOption } = this.state;
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (typeof fields["firstName"] !== "undefined") {
            if (fields["firstName"].length < 2) {
                formIsValid = false;
                errors["firstName"] = "FirstName must contain 2 characters";
            }
            if (fields["firstName"].length > 50) {
                formIsValid = false;
                errors["firstName"] = "FirstName should not exceed 50 characters";
            }
            if (!fields["firstName"]) {
                formIsValid = false;
                errors["firstName"] = "*Required";
            }
        }


        if (typeof fields["lastName"] !== "undefined") {
            if (fields["lastName"].length < 1) {
                formIsValid = false;
                errors["lastName"] = "LastName must contain 1 characters";
            }
            if (fields["lastName"].length > 50) {
                formIsValid = false;
                errors["lastName"] = "LastName should not exceed 50 characters";
            }
            if (!fields["lastName"]) {
                formIsValid = false;
                errors["lastName"] = "*Required";
            }
        }
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "*Required";
        } else {
            const textcaseRegExp = /^[\w-\.+]+@([\w-]+\.)+[\w-]{2,4}$/;
            const emailvalid = textcaseRegExp.test(fields["email"]);
            if (!emailvalid) {
                formIsValid = false;
                errors["email"] = "Invalid Email";
            }
        }
        if (selectedZipOption === undefined || selectedZipOption === null) {
            formIsValid = false;
            errors["zipCode"] = "*Required";
        }
        
        if (selectedGenderOption === null) {
            formIsValid = false;
            errors["gender"] = "*Required";
        }
        if (selectedGenderOption === undefined) {
            formIsValid = false;
            errors["gender"] = "*Required";
        }

        if (selectedStatusOption === null) {
            formIsValid = false;
            errors["status"] = "*Required";
        }
        if (selectedStatusOption === undefined) {
            formIsValid = false;
            errors["status"] = "*Required";
        }
        if (selectGraduationOption === null) {
            formIsValid = false;
            errors["graduationYear"] = "*Required";
        }
        if (selectGraduationOption === undefined) {
            formIsValid = false;
            errors["graduationYear"] = "*Required";
        }

        if (this.props?.isgetusergoals && this.props?.isgetusergoals?.goals !== undefined) {
            let goalesData = this.props.isgetusergoals.goals
            if (goalesData && goalesData.length > 0) {
                goalesData && goalesData.map((data, key) => {
                    if (data == "gpa") {
                        if (typeof fields["currentGPA"] !== "undefined") {
                            const decimalcaseRegExp = (/^[1-9]\.\d{1,2}$/);
                            const digitcaseRegExp = (/^[1-9]$/);
                            const floatcasenumber = decimalcaseRegExp.test(fields["currentGPA"]);
                            const digitcasenumber = digitcaseRegExp.test(fields["currentGPA"]);
                            if (!digitcasenumber && !floatcasenumber) {
                                formIsValid = false;
                                errors["currentGPA"] = "Invalid GPA";
                            }
                        }
                        if (!fields["currentGPA"]) {
                            formIsValid = false;
                            errors["currentGPA"] = "*Required";
                        }
                    }
                })
            }
        }
        if (fields["currentGPA"] !== "") {
            if (typeof fields["currentGPA"] !== "undefined") {
                const decimalcaseRegExp = /^(?:[1-4](\.\d{1})?|5(\.0{1})?)$/;
                const floatcasenumber = decimalcaseRegExp.test(fields["currentGPA"]);

                if (!floatcasenumber) {
                    formIsValid = false;
                    errors["currentGPA"] = "Invalid GPA";
                }
            }
        }

        if (fields["unWeighted"] !== "") {
            if (typeof fields["unWeighted"] !== "undefined") {
                const decimalcaseRegExp = /^(?:[1-3](\.\d{1})?|4(\.0{1})?)$/;
                const floatcasenumber = decimalcaseRegExp.test(fields["unWeighted"]);

                if (!floatcasenumber) {
                    formIsValid = false;
                    errors["unWeighted"] = "Invalid GPA";
                }
            }
        }

        if (selectSchoolOption === null) {
            formIsValid = false;
            errors["highSchoolName"] = "*Required";
        }
        if (selectSchoolOption === undefined) {
            formIsValid = false;
            errors["highSchoolName"] = "*Required";
        }
        if (selectedCountryOption === null) {
            formIsValid = false;
            errors["country"] = "*Required";
        }
        if (selectedCountryOption === undefined) {
            formIsValid = false;
            errors["country"] = "*Required";
        }
        if (!this?.state?.studentsCollabCheckBox && !this?.state?.counselingAllowedCheckBox && !this?.state?.subCouncelingServicesCheckBox && !this?.state?.helpWithMilestonesCheckBox) {
            formIsValid = false;
            errors["checkboxes"] = "*Required";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    handleScroll = () => {
        window.scrollTo(0, 400)
    }
    handleNext = async (e) => {
        e.preventDefault();
        if (this.handleValidation()) {
            let fields = this.state.fields;
            let { selectedGenderOption, selectedStatusOption, selectedCountryOption, selectGraduationOption, selectSchoolOption, selectTimezoneOption,selectedZipOption } = this.state;
            let { studentsCollabCheckBox, counselingAllowedCheckBox, subCouncelingServicesCheckBox, helpWithMilestonesCheckBox } = this.state;
            let values = {
                firstName: fields["firstName"],
                lastName: fields["lastName"],
                email: this.state.fields["email"],
                graduationYear: selectGraduationOption.label,
                zipCode: selectedZipOption,
                highSchoolName: selectSchoolOption?.label || selectSchoolOption,
                weighted: fields["currentGPA"] || null,
                unweighted: fields["unWeighted"] || null,
                studentsCollab: studentsCollabCheckBox,
                counselingAllowed: false,
                helpWithCoursesAndActivities: counselingAllowedCheckBox,
                subCouncelingServices: subCouncelingServicesCheckBox,
                helpWithMilestones: helpWithMilestonesCheckBox,
                gender: selectedGenderOption.label,
                status: selectedStatusOption.label,
                country: selectedCountryOption.value,
                timeZone: selectTimezoneOption.value
            }
            this.props.updateUserProfile(values);
        }
    };
    onTagsChange = (event, values) => {
        let { errors } = this.state;
        this.setState({ selectSchoolOption: values });
        errors["highSchoolName"] = "";
    }
    onZipTagsChange = (event, values) => {
        console.log("values", values)
        let { errors } = this.state;
        this.setState({ selectedZipOption: values });
        errors['zipCode'] = ""
    }
    handleSchoolApi = (props) => {

        this.props.getSchools({ "searchString": props })
    }
    render() {
        const { gender, status, country, graduationyear } = this.props;
        const { highschoollist, selectedGenderOption,selectedZipOption } = this.state;
        const { selectedStatusOption, selectedCountryOption, selectSchoolOption, selectGraduationOption, selectTimezoneOption } = this.state;
        const { studentsCollabCheckBox, counselingAllowedCheckBox, helpWithMilestonesCheckBox, subCouncelingServicesCheckBox, } = this.state;
        let genderOptions = [];
        let statusOptions = [];
        let countryOptions = [];
        let graduationOptions = [];
        let SchoolOptions = [];
        gender !== undefined && gender.length > 0 && gender.map((x, key) => {
            var temp = { label: x, value: key }
            genderOptions.push(temp);
        });
        status !== undefined && status.length > 0 && status.filter((x) => x.role !== "professional").map((x, key) => {
            var temp = { label: x.status, value: x.id, role: x.role }
            statusOptions.push(temp);
        });
        country !== undefined && country.length > 0 && country.map((x, key) => {
            var temp = { label: x.countryName, value: x.isoCode2Digit }
            countryOptions.push(temp);
        })
        graduationyear !== undefined && graduationyear.length > 0 && graduationyear.map((x, key) => {
            var temp = { label: x, value: x }
            graduationOptions.push(temp);
        })
        highschoollist !== undefined && highschoollist.length > 0 && highschoollist.map((x, key) => {
            var temp = { label: x.school, value: x.school }
            SchoolOptions.push(temp);

        })
        let goalesData = this.props?.isgetusergoals?.goals

        const styles = { placeholder: (base, state) => ({ ...base, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }), }
      console.log(this.state.errorList, "this.state.errorList")
        return (
            <>
                {this.state.loading && <Loader />}
                {this.state.loading || this.props.loading && <Loader />}
                <div className="d-flex justify-content-center mt-2">
                    {this.props.message && !this.props.errorList && <CustomAlert />}
                </div>
                <div className="d-flex justify-content-center" style={{ position: 'relative' }}>
                                                {Object.keys(this.state.errorList)?.length === 0 && <CustomAlert />}
                                            </div>
                <div className="profile">
                    <h1 className="profile__form__title text-center mb-2">
                        <span style={{ "marginRight": "10px" }} >
                            <svg width="48" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.57517 12.3301L2.95169 10.8807C2.60805 10.7432 2.60805 10.2568 2.95169 10.1193L11.2572 6.79711C11.734 6.60638 12.266 6.60638 12.7428 6.79711L21.0483 10.1193C21.392 10.2568 21.392 10.7432 21.0483 10.8807L17.4248 12.3301" stroke="#222222" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M14 13.5C14 13.7761 14.2239 14 14.5 14C14.7761 14 15 13.7761 15 13.5H14ZM14 7.5V13.5H15V7.5H14Z" fill="#222222" />
                                <path d="M6.5 12.5V16.5L12 18.5L17.5 16.5V12.5C17.5 12.5 17 10.5 12 10.5C7 10.5 6.5 12.5 6.5 12.5Z" stroke="#222222" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </span>
                        Create Student Profile</h1>
                    <div className="profile__form">
                        
                        <Form >
                            <div className="row m-0">
                                <div className="col-md-6 col-sm-6 col-12 ps-0 p-mob-0">
                                    <div className="input-floating-label">
                                        <input refs="firstName" id="firstName" type="text" className={"textbox--primary textbox--rounded input"} value={this.state.fields["firstName"]} name="firstName" placeholder=" " onChange={this.handleChange.bind(this, "firstName")} />
                                        <label>{FIRST_NAME_LABEL}</label>
                                        {this.state.errors["firstName"] && <span className="error-text ps-0">{this.state.errors["firstName"]}</span>}
                                        {this.state.errorList?.firstName && <span className="error-text ps-0">{this.state.errorList?.firstName}</span>}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-12 pe-0 p-mob-0">
                                    <div className="input-floating-label">
                                        <input refs="lastName" id="lastName" type="text" className={"textbox--primary textbox--rounded input"} name="lastName" placeholder=" " onChange={this.handleChange.bind(this, "lastName")} value={this.state.fields["lastName"]} />
                                        <label>{LAST_NAME_LABEL}</label>
                                        {this.state.errors["lastName"] && <span className="error-text ps-0">{this.state.errors["lastName"]}</span>}
                                        {this.state.errorList?.lastName && <span className="error-text ps-0">{this.state.errorList?.lastName}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-12">
                                    <div className="input-floating-label">
                                        <input refs="email" id="email" type="email" className={"textbox--primary textbox--rounded input"} value={this.state.fields["email"]} name="email" placeholder=" " onChange={this.handleChange.bind(this, "email")} />
                                        <label>{EMAIL_LABEL}</label>
                                        {this.state.errors["email"] && <span className="error-text">{this.state.errors["email"]}</span>}
                                        {this.state.errorList?.email && <span className="error-text">{this.state?.errorList?.email}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-md-6 col-sm-6 col-12 ps-0 p-mob-0">
                                    <div className="input-floating-label">
                                        <Select value={selectedCountryOption} id="country" options={countryOptions} placeholder={COUNTRY_NAME_LABEL} className="goals__form__select mb-0" classNamePrefix="mySelect" name="country" closeMenuOnSelect={true} isClearable={false} onChange={(value) => this.handleSelectChange(value, 'country')} components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }} styles={{ option: (provided, state) => ({ ...provided, cursor: 'pointer', }), container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) }} />
                                        {this.state.errors["country"] && <span className="error-text ps-0">{this.state.errors["country"]}</span>}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-12 pe-0 p-mob-0">
                                    <Autocomplete className="goals__form__select" id="zipCode-filled" name="zipCode" type='text' clearOnBlur={false} filterSelectedOptions forcePopupIcon={false} freeSolo={true} openOnFocus={true}
                                        options={this.state.zipCodeOptions?.length > 0 ? this.state.zipCodeOptions.map((option) => option.label) : []}
                                        filterOptions={(options, state) => options} value={selectedZipOption !== null ? selectedZipOption : []}
                                        onChange={this.onZipTagsChange} onInputChange={async (event, value) => { this.ziploadOptions(value) }}
                                        renderInput={(params) => (
                                            <div className="input-floating-label" ref={params.InputProps.ref} style={{ marginBottom: '20px' }}>
                                                <input type="text" {...params.inputProps} className={"textbox--primary textbox--rounded input"} id="zipCode" name="zipCode" placeholder=" " />
                                                <label>{ZIP_CODE_LABEL}</label>
                                                {this.state.errors["zipCode"] && <span className="error-text ps-0">{this.state.errors["zipCode"]}</span>}
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-md-12 col-sm-12 col-12 p-0">
                                    <div className="input-floating-label">
                                        <Select placeholder={"Time Zone"} closeMenuOnSelect={true} value={selectTimezoneOption} isClearable={false} onChange={(value) => this.handleSelectChange(value, 'timezone')}
                                            isSearchable={false} classNamePrefix="mySelect" options={timezonesOptions} components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                            styles={{ option: (provided, state) => ({ ...provided, cursor: 'pointer', }), container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-md-12 col-sm-12 col-12 p-0">
                                    <div className="input-floating-label">
                                        <Select className="goals__form__select mb-0" id="gender" classNamePrefix="mySelect" name="gender" options={genderOptions} closeMenuOnSelect={true} isClearable={false} onChange={(value) => this.handleSelectChange(value, 'gender')} placeholder={GENDER_LABEL} value={selectedGenderOption} components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }} styles={{ option: (provided, state) => ({ ...provided, cursor: 'pointer', }), container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) }} />
                                        {this.state.errors["gender"] && <span className="error-text ps-0">{this.state.errors["gender"]}</span>}
                                    </div>

                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-md-12 col-sm-12 col-12 p-0">
                                    <div className="input-floating-label">
                                        <Select isDisabled={this?.props?.userProfile?.studentProfile?.myStatus !== null} id="status" onFocus={(e) => this.handleScroll(e)} className="goals__form__select mb-0" classNamePrefix="mySelect" name="status" options={statusOptions} closeMenuOnSelect={true} isClearable={false} onChange={(value) => this.handleSelectChange(value, 'status')} value={selectedStatusOption} components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }} placeholder={MY_STATUS_LABEL} styles={{ option: (provided, state) => ({ ...provided, cursor: 'pointer', }), container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10 }) }} />
                                        {this.state.errors["status"] && <span className="error-text ps-0">{this.state.errors["status"]}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-md-12 col-sm-12 col-12 p-0">
                                    <div className="input-floating-label">
                                        <Select className="goals__form__select " id="graduationYear" classNamePrefix="mySelect" name="graduationYear" options={graduationOptions} closeMenuOnSelect={true} isClearable={false} onChange={(value) => this.handleSelectChange(value, 'graduationYear')} value={selectGraduationOption} components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }} placeholder={GRADUATION_YEAR_LABEL} styles={{ option: (provided, state) => ({ ...provided, cursor: 'pointer', }), container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10 }) }} />
                                        {this.state.errors["graduationYear"] && <span className="error-text ps-0">{this.state.errors["graduationYear"]}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-md-6 col-sm-6 col-12 ps-0 p-mob-0">
                                    <div className="input-floating-label">
                                        <input refs="currentGPA" id="weightedgpa" onKeyPress={(event) => { if (!/[0-9.]/.test(event.key)) { event.preventDefault(); } }} type="text" className={"textbox--primary textbox--rounded input"} name="currentGPA" placeholder={GPA_LABEL} onChange={this.handleChange.bind(this, "currentGPA")} value={this.state.fields["currentGPA"]} />
                                        <label>{"Weighted GPA"}</label>
                                        {!goalesData?.includes('gpa') &&
                                            <p className="cta--text mb-2" style={{ "fontSize": "12px", "marginLeft": "20px" }}>Optional</p>
                                        }
                                        {this.state.errors["currentGPA"] && <span className="error-text ps-0">{this.state.errors["currentGPA"]}</span>}
                                        {this.state.errorList?.weighted && <span className="error-text">{this.state?.errorList?.weighted}</span>}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-12 pe-0 p-mob-0">
                                    <div className="input-floating-label">
                                        <input refs="unWeighted" id="unWeightedgpa" onKeyPress={(event) => { if (!/[0-9.]/.test(event.key)) { event.preventDefault(); } }} type="text" className={"textbox--primary textbox--rounded input"} name="unWeighted" placeholder={GPA_LABEL} onChange={this.handleChange.bind(this, "unWeighted")} value={this.state.fields["unWeighted"]} />
                                        <label>{"Unweighted GPA"}</label>
                                        <p className="cta--text mb-2" style={{ "fontSize": "12px", "marginLeft": "20px" }}>Optional</p>
                                        {this.state.errors["unWeighted"] && <span className="error-text ps-0">{this.state.errors["unWeighted"]}</span>}
                                        {this.state.errorList?.unweighted && <span className="error-text">{this.state?.errorList?.unweighted}</span>}
                                    </div>
                                </div>
                            </div>
                            {selectedStatusOption?.role === "highschool" ? (
                                <div className="row m-0 mb-4">
                                    <div className="col-md-12 col-sm-12 col-12 p-0 asyncmultiselect multinew" >
                                        <Autocomplete className="goals__form__select" id="highschoolDropdown" name="highschoolDropdown" type='text'
                                            freeSolo clearOnBlur={false} clearIcon={false} noOptionsText={'There is no school related to your search'}
                                            options={this.state.highSchoolData?.length > 0 ? this.state.highSchoolData.map((option) => option.label) : []}
                                            filterOptions={(options, state) => options} value={selectSchoolOption?.label || selectSchoolOption}
                                            onChange={this.onTagsChange} onInputChange={async (event, value) => { this.loadOptions(value) }}
                                            renderInput={(params) => (
                                                <div className="input-floating-label" ref={params.InputProps.ref} style={{ marginBottom: '4px' }}>
                                                    <input type="text" {...params.inputProps} className={"textbox--primary textbox--rounded input"} name="email" placeholder=" " />
                                                    <label>{"High School Name"}</label>
                                                </div>
                                            )}
                                        />
                                        {this.state.errors["highSchoolName"] && <span className="error-text ps-0">{this.state.errors["highSchoolName"]}</span>}
                                        {this.state.errorList?.highSchoolName && <span className="error-text ps-0">{this.state.errorList?.highSchoolName}</span>}
                                    </div>
                                </div>
                            ) : (
                                <div className="row m-0 mb-4">
                                    <div className="col-md-12 col-sm-12 col-12 p-0 asyncmultiselect multinew" >
                                        <Autocomplete className="goals__form__select" id="Universitydropdown" name="Universitydropdown" type='text'
                                            freeSolo clearOnBlur={false} clearIcon={false} noOptionsText={'There is no school related to your search'}
                                            options={this.state.highSchoolData?.length > 0 ? this.state.highSchoolData.map((option) => option.label) : []}
                                            filterOptions={(options, state) => options} value={selectSchoolOption?.label || selectSchoolOption}
                                            onChange={this.onTagsChange} onInputChange={async (event, value) => { this.UniversityloadOptions(value) }}
                                            renderInput={(params) => (
                                                <div className="input-floating-label" ref={params.InputProps.ref} style={{ marginBottom: '4px' }}>
                                                    <input type="text" {...params.inputProps} className={"textbox--primary textbox--rounded input"} name="email" placeholder=" " />
                                                    <label>{"University Name"}</label>
                                                </div>
                                            )}
                                        />
                                        {this.state.errors["highSchoolName"] && <span className="error-text ps-0">{this.state.errors["highSchoolName"]}</span>}
                                        {this.state.errorList?.highSchoolName && <span className="error-text ps-0">{this.state.errorList?.highSchoolName}</span>}
                                    </div>
                                </div>
                            )}
                            <div style={{ "marginBottom": "100px" }}>
                                <p className="profile__form__sub-title">I need help with</p>
                                <p className="">
                                    <input ref='studentsCollabCheckBox' defaultChecked={studentsCollabCheckBox} className="styled-checkbox" id="styled-checkbox-1" type="checkbox" value="value2" onClick={(e) => this.handleCheckboxChange(e, 'studentsCollab')} />
                                    <label htmlFor="styled-checkbox-1">
                                        <span>Collaborating with students with similar goals</span>
                                    </label>
                                </p>
                                <p className="">
                                    <input ref='counselingAllowedCheckBox' defaultChecked={counselingAllowedCheckBox} className="styled-checkbox" id="styled-checkbox-2" type="checkbox" value="value2" onClick={(e) => this.handleCheckboxChange(e, 'counselingAllowed')} />
                                    <label htmlFor="styled-checkbox-2">
                                        <span>Selecting courses/activities to meet my goal</span>
                                    </label>
                                </p>
                                <p className="d-flex">
                                    <input ref='helpWithMilestonesCheckBox' defaultChecked={helpWithMilestonesCheckBox} className="styled-checkbox" id="styled-checkbox-3" type="checkbox" value="value2" onClick={(e) => this.handleCheckboxChange(e, 'helpWithMilestones')} />
                                    <label htmlFor="styled-checkbox-3">
                                        <span>Help with course/activity milestones through school year</span>
                                        <Tooltip title="Assignments, homework, test prep etc" placement="right">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.00008 7.16699C8.27622 7.16699 8.50008 7.39085 8.50008 7.66699V11.0003C8.50008 11.2765 8.27622 11.5003 8.00008 11.5003C7.72394 11.5003 7.50008 11.2765 7.50008 11.0003V7.66699C7.50008 7.39085 7.72394 7.16699 8.00008 7.16699Z" fill="#919293" />
                                                <path d="M8.00008 6.00033C8.36827 6.00033 8.66675 5.70185 8.66675 5.33366C8.66675 4.96547 8.36827 4.66699 8.00008 4.66699C7.63189 4.66699 7.33341 4.96547 7.33341 5.33366C7.33341 5.70185 7.63189 6.00033 8.00008 6.00033Z" fill="#919293" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M2.16675 8.00033C2.16675 4.77866 4.77842 2.16699 8.00008 2.16699C11.2217 2.16699 13.8334 4.77866 13.8334 8.00033C13.8334 11.222 11.2217 13.8337 8.00008 13.8337C4.77842 13.8337 2.16675 11.222 2.16675 8.00033ZM8.00008 3.16699C5.3307 3.16699 3.16675 5.33095 3.16675 8.00033C3.16675 10.6697 5.3307 12.8337 8.00008 12.8337C10.6695 12.8337 12.8334 10.6697 12.8334 8.00033C12.8334 5.33095 10.6695 3.16699 8.00008 3.16699Z" fill="#919293" />
                                            </svg>
                                        </Tooltip>
                                    </label>
                                </p>
                                <p className="">
                                    <input ref='subCouncelingServicesCheckBox' defaultChecked={subCouncelingServicesCheckBox} className="styled-checkbox" id="styled-checkbox-4" type="checkbox" value="value2" onClick={(e) => this.handleCheckboxChange(e, 'subCouncelingServices')} />
                                    <label htmlFor="styled-checkbox-4">
                                        <span>Professional counseling services</span>
                                    </label>
                                </p>
                                {this.state.errors["checkboxes"] && <span className="error-text ps-0">{this.state.errors["checkboxes"]}</span>}
                            </div>
                        </Form>
                    </div>
                </div>
                <Footer currentStep="" reverseLink={"/onboarding/goals"}>
                    <Link className="footers__rightSec__cta" to="#" id="nextbtn" onClick={(e) => this.handleNext(e)} >Set up a plan
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.92893 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.0222 21.4135 8.08879 20.3147 6.4443C19.2159 4.79981 17.6541 3.51808 15.8268 2.76121C13.9996 2.00433 11.9889 1.8063 10.0491 2.19215C8.10929 2.578 6.32746 3.53041 4.92893 4.92893" strokeWidth="2" className="rolestrokes" />
                            <path d="M15 12L15.7809 11.3753L16.2806 12L15.7809 12.6247L15 12ZM3 13C2.44771 13 2 12.5523 2 12C2 11.4477 2.44771 11 3 11V13ZM11.7809 6.3753L15.7809 11.3753L14.2191 12.6247L10.2191 7.6247L11.7809 6.3753ZM15.7809 12.6247L11.7809 17.6247L10.2191 16.3753L14.2191 11.3753L15.7809 12.6247ZM15 13H3V11H15V13Z" className="rolefills" />
                        </svg>
                    </Link>
                </Footer>
                {/* <div className="footer">
                    <div className="row m-0">
                        <div className="footer__left col-md-4 col-sm-4 col-4 text-center p-0">
                            <p>
                                <Link to="/onboarding/goals" onClick={() => this.props.setIsGoalSuccess(false)} className="footer__left__cta">
                                    <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.80474 0.195262C5.54439 -0.0650874 5.12228 -0.0650874 4.86193 0.195262L0.195262 4.86193C-0.0650873 5.12228 -0.0650873 5.54439 0.195262 5.80474L4.86193 10.4714C5.12228 10.7318 5.54439 10.7318 5.80474 10.4714C6.06509 10.2111 6.06509 9.78894 5.80474 9.52859L1.60948 5.33333L5.80474 1.13807C6.06509 0.877722 6.06509 0.455612 5.80474 0.195262Z" fill="#1B1C1E" />
                                    </svg>
                                    <span>Go back</span>
                                </Link>
                            </p>
                        </div>
                        <div className="footer__center col-md-4 col-sm-4 col-4 text-center">
                            <p>2 out of 7 steps</p>
                        </div>
                        <div className="footer__right col-md-4 col-sm-4 col-4 text-center">
                            <Link className="footer__cta" to="#" onClick={e => this.handleNext(e)}>Set up a plan
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.92893 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.0222 21.4135 8.08879 20.3147 6.4443C19.2159 4.79981 17.6541 3.51808 15.8268 2.76121C13.9996 2.00433 11.9889 1.8063 10.0491 2.19215C8.10929 2.578 6.32746 3.53041 4.92893 4.92893" strokeWidth="2" className="rolestrokes" />
                                    <path d="M15 12L15.7809 11.3753L16.2806 12L15.7809 12.6247L15 12ZM3 13C2.44771 13 2 12.5523 2 12C2 11.4477 2.44771 11 3 11V13ZM11.7809 6.3753L15.7809 11.3753L14.2191 12.6247L10.2191 7.6247L11.7809 6.3753ZM15.7809 12.6247L11.7809 17.6247L10.2191 16.3753L14.2191 11.3753L15.7809 12.6247ZM15 13H3V11H15V13Z" className="rolefills" />
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div> */}
            </>
        );
    }
}

const mapStateToProps = ({ commonData, userProfileData, studentData }) => {
    const { message, errorList, status, gender, country, loading, schools, graduationyear } = commonData
    const { isProfileSuccess, userProfile, getprofilestatus } = userProfileData;
    const { isgetusergoals, connectedparent } = studentData;
    return { connectedparent, message, errorList, status, gender, isProfileSuccess, userProfile, getprofilestatus, country, loading, schools, graduationyear, isgetusergoals, }
};
export default connect(mapStateToProps, { ConnectParent, getGender, getStatus, updateUserProfile, getUserProfile, setIsGoalSuccess, getCountry, getSchools, clearMessage, getUserGoals, clearErrorList, getUserProfilestatus })(withRouter(Index));